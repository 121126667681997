import React from "react";
import { Link, Redirect } from "react-router-dom";

import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
export const Explore = () => {
  return (
    <div>
      <div class="container-fluid ">
        <nav class="navbar navbar-expand-lg bg-body-tertiary top-fluid">
          <div class="container-fluid ">
            <Link class="navbar-brand" to="/">
              <img
                src="./t Text.svg"
                height="50"
                width="100%"
                alt=""
                className="mb-2"
              />
            </Link>
            {/* <a class="navbar-brand">
              <div className="connect-btn-contact">
                <Link to="/create">
                  <div className="connect-content">Register</div>
                </Link>
              </div>
            </a> */}
          </div>
        </nav>
        <div className="container">
          <ul
            class="nav nav-tabs mt-5 d-flex justify-content-center"
            id="myTab"
            role="tablist"
          >
            <li class="nav-item" role="presentation">
              <button
                class="nav-link active"
                id="home-tab"
                data-toggle="tab"
                data-target="#home"
                type="button"
                role="tab"
                aria-controls="home"
                aria-selected="true"
                style={{ width: "100px" }}
              >
                Live
              </button>
            </li>
            <li class="nav-item" role="presentation">
              <button
                class="nav-link"
                id="profile-tab"
                data-toggle="tab"
                data-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                aria-selected="false"
                style={{ width: "100px" }}
                disabled
              >
                Upcoming
              </button>
            </li>
            <li class="nav-item " role="presentation">
              <button
                class="nav-link"
                id="contact-tab"
                data-toggle="tab"
                data-target="#contact"
                type="button"
                role="tab"
                aria-controls="contact"
                aria-selected="false"
                style={{ width: "100px" }}
                disabled
              >
                Finished
              </button>
            </li>
          </ul>
          <div className="margin-tab">
            <div class="tab-content" id="myTabContent">
              <div
                class="tab-pane fade show active"
                id="home"
                role="tabpanel"
                aria-labelledby="home-tab"
              >
                <div className="row card-wrma d-flex justify-content-center">
                  <div className="col-md-4 mt-2 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <div className="">
                          <div
                            style={{
                              width: 44,
                              height: 47.99,
                              left: 0,
                              top: -1.09,
                              position: "absolute",
                              background: "#515151",
                              padding: "3px",
                              borderRadius: "5px",
                            }}
                            className="m-2"
                          >
                            <img src="/images/zig/arbitrum 1.svg" alt="" />
                            <div
                              className=""
                              style={{
                                position: "absolute",
                                left: "24px",
                                top: "36px",
                              }}
                            >
                              <img src="/images/zig/gradientLogo.svg" alt="" />
                            </div>
                          </div>
                        </div>
                        <div
                          style={{
                            color: "white",
                            fontSize: 24,
                            fontFamily: " Clash Grotesk,sans-serif",
                            fontWeight: "500",
                            wordWrap: "break-word",
                            position: " relative",
                            left: " 46px",
                            top: " -3px",
                          }}
                        >
                          Arbitrum{" "}
                          <span style={{ position: "absolute", right: "46px" }}>
                            🔥
                          </span>
                        </div>
                        <div
                          style={{
                            color: "white",
                            fontSize: 16,
                            fontFamily: " Clash Grotesk,sans-serif",
                            fontWeight: "500",
                            wordWrap: "break-word",
                          }}
                          className="mt-5"
                        >
                          Overview
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Blockchain
                            <br />
                            Type
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            WYZth/Arbitrum
                            <br />
                            Layer 1/Layer 2{" "}
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Peg Ratio
                            <br />
                            Circulating Supply Cap
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            1:1
                            <br />
                            1,000,000,000 ARB
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Access
                            <br />
                            Concentrated Ratio
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            wARB-ARB
                            <br />
                            50%
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Bridge-in/out
                            <br />
                            Flavour
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            WYZth/ARB{" "}
                            <a
                              target="_blank"
                              href="https://bridge.wyscale.com/"
                            >
                              <i class="fas fa-external-link-alt text-success"></i>
                            </a>
                            <br />
                            Multi-Chain
                          </div>
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Round
                            <br />
                            Role
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Public
                            <br />
                            DeFi Participants
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Participation Ratio
                            <br />
                            Investment Type
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            1: <del className="text-white">5</del> 3
                            <span className="ml-1">
                              <Tooltip
                                title="It’s mandatory to bring at least 3 participant i.e. 1:3"
                                placement="top"
                              >
                                <IconButton>
                                  <i
                                    class="fas fa-info-circle"
                                    style={{ color: "#a2d254", size: "10" }}
                                  ></i>
                                </IconButton>
                              </Tooltip>
                            </span>
                            <br />
                            DeFi
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Investment Token
                            <br />
                            Investment Ratio
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            80 wARB+ARB
                            <br />
                            50:50
                          </div>
                        </div>
                        <div className="d-flex justify-content-between pt-3">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Incentivized Token
                            <br />
                            Incentivized Ratio
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            ARB
                            <br />
                            100%
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Blockchain
                            <br />
                            Multiplier
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Arbitrum
                            <br />
                            Upto 12x
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Pool Analytics
                            <br />
                            Platform Fees
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Geometry
                            <br />
                          No Fees
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <div
                            style={{
                              color: "#7D7B7B",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            Start Date/Time
                            <br />
                          </div>
                          <div
                            style={{
                              textAlign: "right",
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            23-11-2023/11:30 (UTC)
                          </div>
                        </div>
                        <Link to="/create">
                          <div className="connect-btn-contact mt-3">
                            <div className="connect-content fw-bolder">
                              Participate Now <span>🚀</span>
                            </div>
                          </div>
                        </Link>
                      </div>{" "}
                    </div>{" "}
                  </div>{" "}
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="profile"
                role="tabpanel"
                aria-labelledby="profile-tab"
              >
                <div className="row card-wrma">
                  <div className="col-md-4 mt-2 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <div
                          style={{
                            left: 72,
                            top: 10,
                            position: "absolute",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 15,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                left: 0,
                                top: 0,
                                position: "absolute",
                                background: "#2F2F39",
                                borderRadius: 4,
                              }}
                            />
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                left: 4.5,
                                top: 4.5,
                                position: "absolute",
                              }}
                            >
                              <div
                                style={{
                                  width: 15,
                                  height: 14.99,
                                  left: 0,
                                  top: -1.09,
                                  position: "absolute",
                                  // background: "#FFD300",
                                }}
                              >
                                <img
                                  src="/images/zig/simple-icons_binance.svg"
                                  alt=""
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            stBNB - wBNB
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="d-flex justify-content-between">
                            <div>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                Price
                              </div>
                              <div
                                style={{
                                  color: "#A2D254",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                1 USDC
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                Targeted Raise
                              </div>
                              <div
                                style={{
                                  color: "#A2D254",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                100 USDC
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  mt-2 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <div
                          style={{
                            left: 72,
                            top: 10,
                            position: "absolute",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 15,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                left: 0,
                                top: 0,
                                position: "absolute",
                                background: "#2F2F39",
                                borderRadius: 4,
                              }}
                            />
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                left: 4.5,
                                top: 4.5,
                                position: "absolute",
                              }}
                            >
                              <div
                                style={{
                                  width: 15,
                                  height: 14.99,
                                  left: 0,
                                  top: 0.01,
                                  position: "absolute",
                                  // background: "#FFD300",
                                }}
                              >
                                <img
                                  src="/images/zig/arbitrum 1.svg"
                                  alt=""
                                  height={15}
                                  width={15}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              color: "white",
                              fontSize: 24,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            stARB-wARB
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="d-flex justify-content-between">
                            <div>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 20,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                Price
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "#A2D254",
                                  fontSize: 20,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                1 USDC
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "white",
                                  fontSize: 20,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                Targeted Raise
                              </div>
                              <div
                                style={{
                                  textAlign: "center",
                                  color: "#A2D254",
                                  fontSize: 20,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                1000 USDC
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4  mt-2 mb-2">
                    <div className="card">
                      <div className="card-body">
                        <div
                          style={{
                            left: 72,
                            top: 10,
                            position: "absolute",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            gap: 15,
                            display: "inline-flex",
                          }}
                        >
                          <div
                            style={{
                              width: 24,
                              height: 24,
                              position: "relative",
                            }}
                          >
                            <div
                              style={{
                                width: 24,
                                height: 24,
                                left: 0,
                                top: 0,
                                position: "absolute",
                                background: "#2F2F39",
                                borderRadius: 4,
                              }}
                            />
                            <div
                              style={{
                                width: 15,
                                height: 15,
                                left: 4.5,
                                top: 4.5,
                                position: "absolute",
                              }}
                            >
                              <div
                                style={{
                                  width: 15,
                                  height: 14.99,
                                  left: 0,
                                  top: 0.01,
                                  position: "absolute",
                                }}
                              >
                                <img
                                  src="/images/zig/tron-trx-logo 1.png"
                                  alt=""
                                  height={15}
                                  width={15}
                                />
                              </div>
                            </div>
                          </div>
                          <div
                            style={{
                              color: "white",
                              fontSize: 14,
                              fontFamily: " Clash Grotesk,sans-serif",
                              fontWeight: "500",
                              wordWrap: "break-word",
                            }}
                          >
                            stBNB - wBNB
                          </div>
                        </div>
                        <div className="pt-5">
                          <div className="d-flex justify-content-between">
                            <div>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                Price
                              </div>
                              <div
                                style={{
                                  color: "#A2D254",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                1 USDC
                              </div>
                            </div>
                            <div>
                              <div
                                style={{
                                  color: "white",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                Targeted Raise
                              </div>
                              <div
                                style={{
                                  color: "#A2D254",
                                  fontSize: 14,
                                  fontFamily: " Clash Grotesk,sans-serif",
                                  fontWeight: "500",
                                  wordWrap: "break-word",
                                }}
                              >
                                100 USDC
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="tab-pane fade"
                id="contact"
                role="tabpanel"
                aria-labelledby="contact-tab"
              >
                ..ddeeeee.
              </div>
            </div>
          </div>
        </div>
        <hr color="#595959" className="footer-box" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-8 col-md-8">
              <a class="navbar-brand" href="#">
                <img
                  src="./t Text.svg"
                  height="50"
                  width="100%"
                  alt=""
                  className="mb-2"
                />
              </a>
              <div
                style={{
                  color: "#7D7B7B",
                  fontSize: 16,
                  fontFamily: " Clash Grotesk,sans-serif",
                  fontWeight: "500",
                  wordWrap: "break-word",
                }}
              >
                © 2023 WYScale | All rights reserved
              </div>
              <div>
                <span
                  style={{
                    color: "#7D7B7B",
                    fontSize: 16,
                    fontFamily: " Clash Grotesk,sans-serif",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  For Support Contact Us @{" "}
                </span>
                <a className="number" href="tel:+9118001204099">
                  <span
                    style={{
                      color: "white",
                      fontSize: 16,
                      fontFamily: "Clash Grotesk, sans-serif",
                      fontWeight: 500,
                      textDecoration: "underline",
                      wordWrap: "break-word",
                    }}
                  >
                    18001204099
                  </span>
                </a>  
              </div>
            </div>

            <div className="col-lg-2 col-md-2 col-6  mt-3">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 4,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: 28,
                    fontFamily: " Clash Grotesk,sans-serif",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Explore
                </div>
                <a href="https://wyscale.com/newbies" target="_blank">
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Clash Grotesk,sans-serif",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Newbies
                  </div>
                </a>
                <a href="https://docs.wyscale.com/" target="_blank">
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Clash Grotesk,sans-serif",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Knowledge Base
                  </div>
                </a>
                <a href="https://wyscale.com/TermsOfService" target="_blank">
                  <a href="https://wyscale.com/strategies" target="_blank">
                    <div
                      style={{
                        color: "#9B9B9B",
                        fontSize: 20,
                        fontFamily: " Clash Grotesk,sans-serif",
                        fontWeight: "500",
                        wordWrap: "break-word",
                      }}
                      className="footer-list"
                    >
                      Strategies
                    </div>
                  </a>
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Clash Grotesk,sans-serif",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Terms & Services
                  </div>
                </a>
                <a href="https://wyscale.com/PrivacyPage" target="_blank">
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Clash Grotesk,sans-serif",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Privacy Policy
                  </div>
                </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 col-6 mt-3">
              <div
                style={{
                  width: "100%",
                  height: "100%",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  gap: 4,
                  display: "inline-flex",
                }}
              >
                <div
                  style={{
                    color: "white",
                    fontSize: 28,
                    fontFamily: " Clash Grotesk,sans-serif",
                    fontWeight: "500",
                    wordWrap: "break-word",
                  }}
                >
                  Follow Us
                </div>

                <a href="https://twitter.com/wy_scale" target="_blank">
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Clash Grotesk,sans-serif",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Twitter
                  </div>
                </a>
                <a href="https://www.instagram.com/wy_scale/" target="_blank">
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Clash Grotesk,sans-serif",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Instagram
                  </div>
                </a>
                <a href="https://t.me/wyscale" target="_blank">
                  <div
                    style={{
                      color: "#9B9B9B",
                      fontSize: 20,
                      fontFamily: " Clash Grotesk,sans-serif",
                      fontWeight: "500",
                      wordWrap: "break-word",
                    }}
                    className="footer-list"
                  >
                    Telegram{" "}
                  </div>
                </a>
              </div>
            </div>
          </div>
          <div
            style={{
              color: "white",
              fontSize: 20,
              fontFamily: " Clash Grotesk,sans-serif",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
            className="mt-5"
          >
            Disclaimer
          </div>
          <div
            style={{
              width: "100%",
              color: "#7D7B7B",
              fontSize: 14,
              fontFamily: " Clash Grotesk,sans-serif",
              fontWeight: "500",
              wordWrap: "break-word",
            }}
            className="mb-3"
          >
            <span style={{ fontSize: "8px" }}>
              <i class="fas fa-circle"></i>
            </span>{" "}
            There are substantial risks in investing in a Crypto Portfolio.
            Persons interested in investing in a Crypto Portfolio should
            carefully note the following:
            <br />
            <span style={{ fontSize: "8px" }}>
              <i class="fas fa-circle"></i>
            </span>{" "}
            WYscale is not a broker-dealer, exchange, custodian, or wallet
            provider, and is not intended for frequent trading activity.
            Investing in digital assets is highly speculative and volatile and
            WYscale is only suitable for investors who are willing to bear the
            risk of loss and experience sharp drawdowns.
            <br />
            <span style={{ fontSize: "8px" }}>
              <i class="fas fa-circle"></i>
            </span>{" "}
            WYscale do not offer tax, financial, accounting or legal advice.
            Carefully consider the risk factors, investment objectives, fees,
            expenses, and other information associated with before making an
            investment decision. All investments are speculative, may be
            illiquid and involve a high degree of risk, uncertainty, and there
            is risk of the complete loss of the investment. There is no
            guarantee that any specific outcome will be achieved. Since each
            individual's situation is unique, a qualified professional should
            always be consulted before making any financial decisions.
            <br />
            <span style={{ fontSize: "8px" }}>
              <i class="fas fa-circle"></i>
            </span>{" "}
            The information provided within this website within it is not
            intended as an offer to sell, or the solicitation of an offer to
            buy/invest in any digital assets & funds. Such offerings may only be
            made by prospectus and offering documents.
            <br />
            <span style={{ fontSize: "8px" }}>
              <i class="fas fa-circle"></i>
            </span>{" "}
            WYscale would like to remind you that the data contained in this
            website is not necessarily real-time nor accurate. All
            cryptocurrencies, baskets, ETF, and fund prices are not provided by
            us but rather by market makers, and so prices may not be accurate
            and may differ from the actual market price, meaning prices are
            indicative and not appropriate for trading purposes. Images, charts
            & stats on this page include illustrations of user experiences
            intended to demonstrate the format of WYscale's interface. These
            images do not reflect actual or performance of our recommendations.
            Past performance is not a guarantee of future results.
            <br />
            <span style={{ fontSize: "8px" }}>
              <i class="fas fa-circle"></i>
            </span>{" "}
            For more complete disclosures and information about how WYscale
            works, please visit our Terms of Service and Help Center.
          </div>
        </div>
        
      </div>
    </div>
  );
};
